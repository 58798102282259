import { notification } from 'antd';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { ApolloLink, HttpLink, ServerError, from, split } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import Cookies from 'js-cookie';
import { signOut } from 'utils/authUtils';

export const httpLinkObject = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_SERVER_ENDPOINT,
  // Additional options
  credentials: 'include'
});

export const uploadClientLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_SERVER_ENDPOINT,
  credentials: 'include'
}) as any;

export const authLink = new ApolloLink((operation, forward) => {
  const isAuthenticated = Cookies.get(process.env.REACT_APP_AUTH_TOKEN as string);
  //Terminate Request
  if (!isAuthenticated) {
    signOut();
  }

  // add the authorization to the headers
  operation.setContext(() => ({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true
    }
  }));

  return forward(operation);
});

export const errorLink = onError((error: ErrorResponse) => {
  //todo fix error duplications for 500 case
  const { graphQLErrors, networkError } = error;
  if (networkError) {
    const errorCode = (networkError as ServerError).statusCode;
    if (errorCode === 401) {
      signOut();
    } else if (errorCode === 500) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong!'
      });
    }
  } else if (graphQLErrors) {
    const message = graphQLErrors[0]?.message === 'Not authorized' ? 'Access Denied!' : 'Something went wrong!';
    notification.error({
      message: 'Error',
      description: message
    });
    if (['production', 'staging'].includes(process.env.REACT_APP_DEPLOY_ENV as string)) {
      Sentry.withScope(scope => {
        scope.setExtras({ graphQLErrors: graphQLErrors });
        Sentry.captureException(error);
      });
    }
  }
});

const wsClient = ActionCable.createConsumer(process.env.REACT_APP_APOLLO_WEBSOCKET_SERVER_ENDPOINT);
const wsLink = new ActionCableLink({ cable: wsClient });

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  from([authLink, errorLink, wsLink]),
  from([authLink, errorLink, uploadClientLink as any])
);
