import { useEffect, useMemo, useRef, useState } from 'react';
import { useVendorSearchLazyQuery } from '@queries/vendorAnalysis';
import { FieldSelectOption } from '../types';
import { debounce as _debounce } from 'lodash';

export function useDebounceSearchVendorList(defaultOptions: FieldSelectOption[] = []) {
  const abortC = useRef<AbortController | null>(null);
  const [getVendors, { data, loading }] = useVendorSearchLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [options, setOptions] = useState<any>(defaultOptions);
  useEffect(() => {
    if (defaultOptions.length) {
      setOptions(defaultOptions ?? []);
    }
  }, [defaultOptions]);

  const onDebounceSearch = useMemo(() => {
    return _debounce((value: string) => {
      if (value) {
        setOptions([]);
        if (abortC.current instanceof AbortController) {
          abortC.current.abort();
        }
        abortC.current = new AbortController();
        getVendors({
          variables: {
            search: value
          },
          context: {
            fetchOptions: {
              signal: abortC.current.signal
            }
          }
        });
      }
    }, 800);
  }, [getVendors]);

  useEffect(() => {
    const vendorSearch = data?.vendorsSearch || [];
    if (vendorSearch.length) {
      setOptions(
        vendorSearch.map(vendor => ({
          value: vendor,
          label: vendor
        }))
      );
    }
  }, [data]);

  return {
    options,
    loading,
    onDebounceSearch
  };
}
