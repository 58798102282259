import { useEffect, useMemo, useRef, useState } from 'react';
import { useAccountGroupsLazyQuery } from '@queries/accountGroup';
import { debounce as _debounce } from 'lodash';

export function useDebounceSearchAccountGroups() {
  const abortC = useRef<AbortController | null>(null);
  const [getAccountGroups, { data, loading }] = useAccountGroupsLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [options, setOptions] = useState<any>();

  const onDebounceSearch = useMemo(() => {
    return _debounce((value: string) => {
      if (value) {
        setOptions([]);
        if (abortC.current instanceof AbortController) {
          abortC.current.abort();
        }
        abortC.current = new AbortController();
        getAccountGroups({
          variables: {
            search: value
          },
          context: {
            fetchOptions: {
              signal: abortC.current.signal
            }
          }
        });
      }
    }, 800);
  }, [getAccountGroups]);

  useEffect(() => {
    const accountGroups = data?.accountGroups || [];
    if (accountGroups.length) {
      setOptions(
        accountGroups.map(accountGroup => ({
          value: parseInt(accountGroup.id),
          label: accountGroup.name
        }))
      );
    }
  }, [data]);

  return {
    options,
    loading,
    onDebounceSearch
  };
}
